<template>
  <div class="main">
    <el-card shadow="never" class="card-box" v-loading="loading">
      <div slot="header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/">主頁</el-breadcrumb-item>
          <el-breadcrumb-item>員工</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <el-card shadow="never" v-show="showDevOption">
        <div slot="header">
          開發者選項
          <el-button type="danger" @click="showDevOption = false" id="hide"
            >隱藏</el-button
          >
        </div>
        <el-button type="primary" @click="autoAddDialog = true"
          >自動新增員工</el-button
        >
        <el-button type="primary" id="hideTableInfo" @click="coverData"
          >遮蓋資料</el-button
        >
      </el-card>

      <el-table :data="pagedTableData" style="" id="empTable">
        <el-table-column label="員工">
          <template slot-scope="scope">
            <el-row>
              <el-col :span="8"
                ><el-avatar :size="40" :src="circleUrl"></el-avatar
              ></el-col>
              <el-col :span="16"
                ><div>
                  <span name="cover">{{ scope.row.name }}</span>
                </div>
                <div>
                  <span name="cover">{{ scope.row.eng_name }}</span>
                </div></el-col
              >
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="郵箱" sortable
          ><template slot-scope="scope">
            <span name="cover">{{ scope.row.email }}</span>
          </template></el-table-column
        >
        <el-table-column prop="teleNo" label="電話號碼" sortable
          ><template slot-scope="scope">
            <span name="cover">{{ scope.row.teleNo }}</span>
          </template></el-table-column
        >
        <el-table-column prop="empDate" label="入職日期" sortable
          ><template slot-scope="scope">
            <span name="cover">{{ scope.row.empDate }}</span>
          </template></el-table-column
        >
        <el-table-column>
          <template slot="header" slot-scope="scope">
            <el-input
              placeholder="搜尋"
              style="float: left; width: auto"
              :id="scope"
              v-model="search"
              clearable
            ></el-input>
            <el-button
              @click="dialogVisible = true"
              icon="el-icon-plus"
              circle
              style="float: left"
              type="text"
              class="addEmployeeBtn"
            ></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
              circle
              @click="viewInfo(scope)"
              icon="el-icon-view"
              plain
              type="info"
            ></el-button>
            <el-button
              @click="editInfo(scope)"
              circle
              icon="el-icon-edit-outline"
              plain
              style="margin-left: 1em"
              type="primary"
            ></el-button>
            <el-popconfirm
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="確定要刪除此員工嗎？"
              style="margin-left: 1em"
              @confirm="confirmDelete(scope)"
            >
              <el-button
                slot="reference"
                plain
                icon="el-icon-delete"
                type="danger"
                circle
                class="employeeDeleteBtn"
              ></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div class="mt-1">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="8"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      :visible.sync="editDialog"
      title="修改員工資料"
      width="40%"
      v-loading="empLoading"
    >
      <el-form
        :model="editForm"
        ref="editForm"
        :rules="formRules"
        style="margin-left: 5em; margin-right: 5em"
        label-width="80px"
      >
        <el-form-item label="全名" prop="fullname">
          <el-input
            type="text"
            v-model="editForm.fullname"
            placeholder="輸入全名"
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名" prop="eng_name">
          <el-input
            type="text"
            v-model="editForm.eng_name"
            placeholder="輸入英文名"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="電話號碼" prop="teleNo">
          <el-input
            v-model="editForm.teleNo"
            placeholder="輸入電話號碼"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input
            v-model="editForm.address"
            placeholder="輸入地址"
            style="width: 100%"
          ></el-input> </el-form-item
        ><el-form-item label="出生日期" prop="dob">
          <el-date-picker
            v-model="editForm.dob"
            placeholder="輸入出生日期"
            type="date"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="入職日期" prop="empDate">
          <el-date-picker
            v-model="editForm.empDate"
            placeholder="輸入入職日期"
            type="date"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="薪金" prop="salary">
          <el-input
            v-model="editForm.salary"
            placeholder="輸入薪金"
            type="number"
            style="width: 100%"
          ></el-input>
          <el-radio v-model="editForm.salaryType" label="month">月薪</el-radio>
          <el-radio v-model="editForm.salaryType" label="day">日薪</el-radio>
          <el-radio v-model="editForm.salaryType" label="hour">時薪</el-radio>
        </el-form-item>
        <el-form-item label="銀行" prop="bank">
          <el-select
            v-model="editForm.bank"
            placeholder="輸入銀行"
            style="width: 100%"
            filterable
            ><el-option
              v-for="(_b, i) in bankList"
              :key="i"
              :value="_b.value"
              :label="_b.label"
            >
            </el-option
          ></el-select>
        </el-form-item>
        <el-form-item label="銀行帳戶" prop="bankaccount">
          <el-input
            v-model="editForm.bankaccount"
            placeholder="輸入銀行帳戶"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onUpdateProfile"
            >確定</el-button
          >
          <el-button type="primary" plain @click="editDialog = false"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible"
      title="新增員工"
      width="40%"
      :close-on-click-modal="false"
    >
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        style="margin-left: 5em; margin-right: 5em"
        label-width="80px"
      >
        <el-steps :active="active" finish-status="success" align-center>
          <el-step title="步驟 1"></el-step>
          <el-step title="步驟 2"></el-step>
          <el-step title="步驟 3"></el-step>
        </el-steps>

        <div v-show="active == 0" class="mt-1">
          <center style="margin-bottom: 1em">
            <vue-qr :text="companyDomain + '?form=true'"></vue-qr>
          </center>
          <el-form-item label="電郵" prop="email">
            <el-input
              v-model="form.email"
              placeholder="輸入電郵"
              style="width: 100%"
              type="text"
            ></el-input>
          </el-form-item>
          <el-form-item label="密碼" prop="password">
            <el-input
              v-model="form.password"
              type="password"
              placeholder="輸入密碼"
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </div>
        <div v-show="active == 1" class="mt-2">
          <el-form-item label="全名" prop="fullname">
            <el-input
              v-model="form.fullname"
              placeholder="輸入全名"
              style="width: 100%"
              type="text"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="英文名" prop="eng_name">
            <el-input
              v-model="form.eng_name"
              placeholder="輸入英文名"
              style="width: 100%"
              type="text"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="電話號碼" prop="teleNo">
            <el-input
              v-model="form.teleNo"
              placeholder="輸入電話號碼"
              style="width: 100%"
              type="number"
              :maxlength="8"
              :minlength="8"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input
              v-model="form.address"
              placeholder="輸入地址"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="出生日期" prop="dob">
            <el-date-picker
              v-model="form.dob"
              placeholder="輸入出生日期"
              type="date"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="入職日期" prop="empDate">
            <el-date-picker
              v-model="form.empDate"
              placeholder="輸入入職日期"
              type="date"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div v-if="active == 2" class="mt-2">
          <el-form-item label="薪金" prop="salary">
            <el-input
              v-model="form.salary"
              placeholder="輸入薪金"
              type="number"
              style="width: 100%"
            ></el-input>
            <el-radio v-model="form.salaryType" label="month" selected
              >月薪</el-radio
            >
            <el-radio v-model="form.salaryType" label="day">日薪</el-radio>
            <el-radio v-model="form.salaryType" label="hour">時薪</el-radio>
          </el-form-item>
          <el-form-item label="銀行" prop="bank">
            <el-select
              v-model="form.bank"
              placeholder="輸入銀行"
              style="width: 100%"
              filterable
              ><el-option
                v-for="(_b, i) in bankList"
                :key="i"
                :value="_b.value"
                :label="_b.label"
              >
              </el-option
            ></el-select>
          </el-form-item>
          <el-form-item label="銀行帳戶" prop="bankaccount">
            <el-input
              v-model="form.bankaccount"
              placeholder="輸入銀行帳戶"
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item>
          <el-button
            type="primary"
            plain
            @click="onCancel"
            v-text="previous_text"
          ></el-button>
          <el-button
            type="primary"
            plain
            @click="onSubmit('form')"
            v-text="next_text"
          ></el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="autoAddDialog"
      title="自動新增員工"
      width="40%"
      :close-on-click-modal="false"
    >
      <el-form
        :model="autoAddForm"
        ref="autoAddForm"
        style="margin-left: 5em; margin-right: 5em"
        label-width="100px"
      >
        <el-form-item label="數量">
          <el-input-number
            v-model="autoAddForm.number"
            placeholder="輸入數量"
            :min="1"
            :max="3"
            step-strictly
          ></el-input-number>
        </el-form-item>
        <el-form-item label="出生日期範圍">
          <el-date-picker
            v-model="autoAddForm.dobRange"
            type="daterange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="入職日期範圍">
          <el-date-picker
            v-model="autoAddForm.empDateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="onAutoAddEmp">確定</el-button>
          <el-button type="primary" plain @click="autoAddDialog = false"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<style>
.hasUpload > div {
  display: none;
}
.cover {
  position: relative;
}

.cover::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 88%;
  height: 100%;
  background-color: black;
  z-index: 1;
}
</style>

<script>
import firebase from "firebase/compat/app";
import VueQr from "vue-qr";
import app from "@/firebase";
export default {
  name: "Employees",
  components: {
    VueQr,
  },
  data() {
    return {
      table: [
        // {
        //     name: "陳大明",
        //     eng_name: "Chan Tai Man",
        //     position: "員工",
        //     deptName: "ABC部",
        //     teleNo: 59382958,
        // },
      ],
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      dialogVisible: false,
      search: "",
      form: {
        fullname: "",
        eng_name: "",
        email: "",
        password: "",
        teleNo: "",
        address: "",
        dob: "",
        empDate: new Date(),
        salary: "",
        salaryType: "month",
        bank: "",
        bankaccount: "",
      },
      editForm: {
        fullname: "",
        eng_name: "",
        teleNo: "",
        address: "",
        dob: "",
        empDate: "",
        salary: "",
        salaryType: "",
        bank: "",
        bankaccount: "",
      },
      autoAddForm: {
        number: 1,
        dobRange: ["1980-01-01T08:00:00.000Z", "2000-01-01T08:00:00.000Z"],
        empDateRange: [
          new Date(new Date().getFullYear(), 0, 1).toISOString(),
          new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 2,
            1
          ).toISOString(),
        ],
      },
      formRules: {
        fullname: [{ required: true, message: "請輸入全名", trigger: "blur" }],
        eng_name: [
          {
            required: true,
            message: "請輸入英文名",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: `請輸入密碼`, trigger: "blur" }],
        email: [
          { required: true, message: "請輸入郵箱", trigger: "blur" },
          { type: "email", message: "請輸入郵箱", trigger: "blur" },
        ],
        teleNo: [
          {
            required: true,
            message: `請輸入電話號碼`,
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: `請輸入地址`,
            trigger: "blur",
          },
        ],
        dob: [
          {
            required: true,
            message: `請輸入生日日期`,
            trigger: `blur`,
          },
        ],
        salary: [
          {
            required: true,
            message: `請輸入薪金`,
            trigger: `blur`,
          },
        ],
        bank: [
          {
            required: true,
            message: `請選擇銀行`,
            trigger: `blur`,
          },
        ],
        bankaccount: [
          {
            required: true,
            message: `請輸銀行號碼`,
            trigger: `blur`,
          },
        ],
      },
      bankList: [],
      hasUpload: "",
      active: 0,
      page: 1,
      pageSize: 8,
      editDialog: false,
      empLoading: false,
      loading_count: 0,
      showDevOption: false,
      uid: "",
      autoAddDialog: false,
      firstNames: [
        [
          "凱晴",
          "芷晴",
          "曉晴",
          "子晴",
          "凱琳",
          "曉彤",
          "紫晴",
          "嘉怡",
          "梓晴",
          "樂瑤",
          "芷欣",
          "心怡",
          "詩雅",
          "樂晴",
          "穎彤",
          "樂怡",
          "曉嵐",
          "曉琳",
          "嘉欣",
          "穎欣",
        ],
        [
          "梓軒",
          "宇軒",
          "子謙",
          "樂軒",
          "子朗",
          "家樂",
          "俊傑",
          "卓霖",
          "子軒",
          "俊熙",
          "浩賢",
          "逸朗",
          "梓朗",
          "俊軒",
          "浩軒",
          "柏熹",
          "樂謙",
          "子樂",
          "卓軒",
          "柏熙",
        ],
      ],

      lastNames: [
        ["陳", "Chan"],
        ["馮", "Fung"],
        ["何", "Ho"],
        ["黃", "Wong"],
        ["李", "Lee"],
        ["林", "Lam"],
        ["劉", "Lau"],
        ["梁", "Leung"],
        ["呂", "Lui"],
        ["羅", "Law"],
        ["馬", "Ma"],
        ["潘", "Poon"],
        ["錢", "Chin"],
        ["秦", "Chin"],
        ["邱", "Yau"],
        ["孫", "Sun"],
        ["汪", "Wong"],
        ["王", "Wong"],
        ["吳", "Ng"],
        ["徐", "Tsui"],
        ["許", "Hui"],
        ["謝", "Tse"],
        ["鄭", "Cheng"],
        ["鍾", "Chung"],
        ["周", "Chow"],
        ["張", "Cheung"],
        ["趙", "Chiu"],
        ["鄒", "Chau"],
        ["朱", "Chu"],
        ["李", "Li"],
        ["梅", "Mui"],
      ],
      engNames: [
        [
          "Emily",
          "Chloe",
          "Jessica",
          "Grace",
          "Isabella",
          "Angela",
          "Jenny",
          "Cathy",
          "Maggie",
          "Nancy",
          "Rachel",
          "Samantha",
          "Tina",
          "Linda",
          "Karen",
        ],
        [
          "Ryan",
          "Jack",
          "David",
          "Andy",
          "Michael",
          "Tom",
          "Kevin",
          "Henry",
          "Jerry",
          "Brian",
          "Simon",
          "Frank",
          "Patrick",
          "Charlie",
          "John",
        ],
      ],
      mailProviders: [
        "gmail.com",
        "yahoo.com",
        "hotmail.com",
        "outlook.com",
        "icloud.com",
        "aol.com",
        "protonmail.com",
        "mail.com",
        "zoho.com",
        "gmx.com",
      ],
      telPrefixes: ["5", "2", "3", "8"],
      districts: [
        "Central and Western",
        "Eastern",
        "Southern",
        "Wan Chai",
        "Sham Shui Po",
        "Kowloon City",
        "Kwun Tong",
        "Wong Tai Sin",
        "Yau Tsim Mong",
        "Islands",
        "Kwai Tsing",
        "North",
        "Sai Kung",
        "Sha Tin",
        "Tai Po",
        "Tsuen Wan",
        "Tuen Mun",
        "Yuen Long",
      ],
      streetNames: [
        "Nathan Road",
        "Des Voeux Road",
        "Queen's Road",
        "Hennessy Road",
        "Lockhart Road",
        "Electric Road",
        "Hoi Yuen Road",
        "Cheung Sha Wan Road",
        "Prince Edward Road",
        "King's Road",
      ],
      buildingNames: [
        "The Center",
        "Bank of China Tower",
        "International Commerce Centre",
        "Two International Finance Centre",
        "The Cullinan",
        "Langham Place Office Tower",
        "Lee Garden Three",
        "Times Square",
        "The Gateway",
        "The Metropolis Tower",
      ],
      salaryTypes: [
        ["month", 10000],
        ["day", 100],
        ["hour", 20],
      ],
      autoAdding: false,
      autoAddCount: 0,
    };
  },
  watch: {
    autoAddCount(count) {
      if (this.autoAdding && count == this.autoAddForm.number) {
        this.autoAdding = false;
        this.$message({
          message: "成功新增" + this.autoAddCount + "名員工",
          type: "success",
        });
        this.getUsers();
        this.loading_count--;
      }
    },
  },
  methods: {
    handleCurrentChange(c) {
      this.page = c;
    },
    onUpdateProfile() {
      this.loading_count++;
      const empID = this.editForm.empID;
      const dob = app.formatDate(new Date(this.editForm.dob).getTime());
      const empDate = app.formatDate(new Date(this.editForm.empDate).getTime());
      const updates = {
        name: this.editForm.fullname,
        eng_name: this.editForm.eng_name,
        address: this.editForm.address,
        bank: this.editForm.bank,
        bank_ac: this.editForm.bankaccount,
        salary: {
          basic: this.editForm.salary,
          type: this.editForm.salaryType,
        },
        tel: this.editForm.teleNo,
        dob: dob,
        empDate: empDate,
      };
      firebase
        .database()
        .ref(`users/${empID}`)
        .update(updates)
        .then(() => {
          this.$message({
            message: `修改成功`,
            type: "success",
          });
        })
        .then(() => {
          this.getUsers();
        })
        .catch((err) => {
          this.$message({
            message: `發生錯誤 ${err.code}: ${err.message}`,
            type: "error",
          });
        })
        .finally(() => {
          this.editDialog = false;
          this.loading_count--;
        });
    },
    viewInfo(scope) {
      this.$router.push(`/employees/views/${scope.row.empID}`);
    },
    editInfo(scope) {
      const empID = scope.row.empID;
      this.editDialog = true;
      this.empLoading = true;
      const userRef = firebase.database().ref("users/" + empID);
      userRef
        .once("value")
        .then((snapshot) => {
          return snapshot.val();
        })
        .then((v) => {
          this.editForm.empID = empID;
          this.editForm.fullname = v.name;
          this.editForm.eng_name = v.eng_name;
          this.editForm.bank = v.bank;
          this.editForm.bankaccount = v.bank_ac;
          this.editForm.address = v.address;
          this.editForm.dob = v.dob;
          this.editForm.salary = v.salary.basic;
          this.editForm.salaryType = v.salary.type;
          this.editForm.teleNo = v.tel;
          this.editForm.empDate = v.empDate;
        })
        .catch((err) => {
          this.$message({
            message: `${err.code}: ${err.message}`,
            type: "error",
          });
        })
        .finally(() => {
          this.empLoading = false;
        });
    },
    onSuccess() {
      this.hasUpload = "hasUpload";
    },
    onRemove() {
      this.hasUpload = "";
    },
    onSubmit(form) {
      if (this.active >= 2) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            this.loading_count++;
            const dob = app.formatDate(new Date(this.form.dob).getTime());
            const empDate = app.formatDate(
              new Date(this.form.empDate).getTime()
            );
            const user = {
              name: this.form.fullname,
              eng_name: this.form.eng_name,
              bank: this.form.bank,
              bank_ac: this.form.bankaccount,
              address: this.form.address,
              dob: dob,
              empDate: empDate,
              role: "user",
              salary: {
                basic: this.form.salary,
                type: this.form.salaryType,
              },
              tel: this.form.teleNo,
              email: this.form.email,
            };

            // CREATE email and password
            firebase
              .auth()
              .createUserWithEmailAndPassword(
                this.form.email,
                this.form.password
              )
              .then((userCredential) => {
                var u = userCredential.user;
                var uid = u.uid;
                const userRef = firebase.database().ref("users/" + uid);
                userRef
                  .update(user)
                  .then(() => {
                    firebase
                      .firestore()
                      .collection("users")
                      .doc(uid)
                      .set({
                        Name: user.name,
                        ID: uid,
                        name_lower: user.name.toLowerCase(),
                        chats: [],
                        profilePic:
                          "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                      })
                      .then(() => {
                        this.$refs["form"].resetFields();
                        this.active = 0;
                        this.$message({
                          message: "成功新增員工",
                          type: "success",
                        });
                      })
                      .catch((err) => {
                        this.$message({
                          message: `發生錯誤 ${err.code}: ${err.message}`,
                          type: "error",
                        });
                      })
                      .finally(() => {
                        this.dialogVisible = false;
                        this.getUsers();
                      });
                  })
                  .catch((err) => {
                    this.$message({
                      message: "發生錯誤: " + err.message,
                      type: "error",
                    });
                  });
              })
              .catch((err) => {
                this.$message({
                  message: `${err.message}`,
                  type: "danger",
                });
              })
              .finally(() => {
                this.loading_count--;
              });
          } else {
            return false;
          }
        });
      } else {
        this.active++;
      }
    },
    getUsers() {
      this.loading_count++;
      this.table = [];
      const userRef = firebase.database().ref("users");
      userRef
        .once("value")
        .then((snapshot) => {
          return snapshot.val();
        })
        .then((value) => {
          for (const o in value) {
            const user = value[o];
            if (user.role != "administrator" && user.role != "developer") {
              this.table.push({
                name: user.name,
                eng_name: user.eng_name,
                email: user.email,
                teleNo: user.tel,
                empDate: user.empDate,
                empID: o,
              });
            }
          }
        })
        .finally(() => {
          this.loading_count--;
        });
    },
    onCancel() {
      if (this.active > 0) {
        this.active--;
      } else {
        this.dialogVisible = !this.dialogVisible;
      }
    },
    confirmDelete(scope) {
      const empID = scope.row.empID;
      firebase
        .database()
        .ref(`users/${empID}`)
        .remove()
        .then(() => {
          this.$message({
            message: `刪除員工成功`,
            type: `success`,
          });
        })
        .then(() => {
          /* 處理 相關員工資料 */
          const deptRef = firebase.database().ref("company/departments");
          deptRef
            .once("value")
            .then((snapshot) => snapshot.val())
            .then((value) => {
              firebase
                .firestore()
                .collection("users")
                .doc(empID)
                .delete()
                .then(() => {
                  const _value = Object.keys(value);
                  _value.forEach((_v) => {
                    if (value[_v].members.manager == empID) {
                      firebase
                        .database()
                        .ref("company/departments/" + _v + "/members")
                        .update({
                          manager: "",
                        });
                    }
                    if (
                      value[_v].members.staff != null &&
                      value[_v].members.staff.empID == 1
                    ) {
                      firebase
                        .database()
                        .ref(
                          "company/departments/" +
                            _v +
                            "/members/staff/" +
                            empID
                        )
                        .remove();
                    }
                  });
                });
            });
        })
        .catch((err) => {
          this.$message({
            message: `發生錯誤 ${err.code}: ${err.message}`,
            type: "error",
          });
        })
        .finally(() => {
          this.getUsers();
          this.page = 1;
        });
    },
    async onAutoAddEmp() {
      this.loading_count++;
      this.autoAddDialog = false;
      this.autoAdding = true;
      this.autoAddCount = 0;
      const number = this.autoAddForm.number;
      for (var i = 0; i < number; i++) {
        const dobStart = new Date(this.autoAddForm.dobRange[0]).getTime();
        const dobEnd = new Date(this.autoAddForm.dobRange[1]).getTime();
        const empDateStart = new Date(
          this.autoAddForm.empDateRange[0]
        ).getTime();
        const empDateEnd = new Date(this.autoAddForm.empDateRange[1]).getTime();
        const lastName =
          this.lastNames[Math.floor(Math.random() * this.lastNames.length)];
        const gender = Math.round(Math.random());
        const firstName =
          this.firstNames[gender][
            Math.floor(Math.random() * this.firstNames[gender].length)
          ];
        const mailProvider =
          this.mailProviders[
            Math.floor(Math.random() * this.mailProviders.length)
          ];
        const engName =
          this.engNames[gender][
            Math.floor(Math.random() * this.engNames[gender].length)
          ];
        const telPrefix =
          this.telPrefixes[Math.floor(Math.random() * this.telPrefixes.length)];
        const salary =
          this.salaryTypes[Math.floor(Math.random() * this.salaryTypes.length)];
        const user = {
          name: lastName[0] + firstName,
          eng_name: engName + " " + lastName[1],
          bank: this.bankList[Math.floor(Math.random() * this.bankList.length)]
            .value,
          bank_ac: Math.floor(Math.random() * 89999999999 + 10000000000),
          address:
            "Unit " +
            Math.floor(Math.random() * 29 + 1) +
            ", " +
            Math.floor(Math.random() * 29 + 1) +
            "/F, " +
            this.buildingNames[
              Math.floor(Math.random() * this.buildingNames.length)
            ] +
            ", " +
            Math.floor(Math.random() * 199 + 1) +
            " " +
            this.streetNames[
              Math.floor(Math.random() * this.streetNames.length)
            ] +
            ", " +
            this.districts[Math.floor(Math.random() * this.districts.length)] +
            ", Hong Kong",
          dob: app.formatDate(dobStart + Math.random() * (dobEnd - dobStart)),
          empDate: app.formatDate(
            empDateStart + Math.random() * (empDateEnd - empDateStart)
          ),
          role: "user",
          salary: {
            basic: Math.floor(Math.random() * 8 + 1) * salary[1],
            type: salary[0],
          },
          tel: telPrefix + Math.floor(Math.random() * 8999999 + 1000000),
          email:
            engName +
            lastName[1] +
            Math.floor(Math.random() * 8999 + 1000) +
            "@" +
            mailProvider,
        };

        firebase
          .auth()
          .createUserWithEmailAndPassword(user.email, "123456")
          .then((userCredential) => {
            var u = userCredential.user;
            var uid = u.uid;
            const userRef = firebase.database().ref("users/" + uid);

            userRef
              .update(user)
              .then(() => {
                firebase
                  .firestore()
                  .collection("users")
                  .doc(uid)
                  .set({
                    Name: user.name,
                    ID: uid,
                    name_lower: user.name.toLowerCase(),
                    chats: [],
                    profilePic:
                      "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
                  })
                  .then(() => {
                    this.autoAddCount++;
                  })
                  .catch((err) => {
                    this.autoAdding = false;
                    this.$message({
                      message: `發生錯誤 ${err.code}: ${err.message}`,
                      type: "error",
                    });
                  });
              })
              .catch((err) => {
                this.autoAdding = false;
                this.$message({
                  message: "發生錯誤: " + err.message,
                  type: "error",
                });
              });
          })
          .catch((err) => {
            this.autoAdding = false;
            this.$message({
              message: `${err.message}`,
              type: "danger",
            });
          });
      }
    },
    coverData() {
      const covers = document.querySelectorAll('[name="cover"]');
      covers.forEach((element) => {
        // element.style.setProperty("text-decoration-line", "line-through");
        // element.style.setProperty("text-decoration-thickness", "1rem");
        element.classList.add("cover");
      });
    },
  },
  computed: {
    loading() {
      return this.loading_count != 0;
    },
    next_text() {
      if (this.active < 2) return "下一步";
      return "確定";
    },
    previous_text() {
      if (this.active > 0) return "上一步";
      return "取消";
    },
    pagedTableData() {
      return this.tableData.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page
      );
    },
    total() {
      return this.tableData.length;
    },
    tableData() {
      return this.table.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase()) ||
          data.teleNo.toLowerCase().includes(this.search.toLowerCase()) ||
          data.eng_name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.uid = user.uid;
        var dbRef = firebase.database().ref("users/" + this.uid);
        dbRef
          .once("value")
          .then((snapshot) => {
            return snapshot.val();
          })
          .then((a) => {
            if (a.role == "developer") {
              this.showDevOption = true;
            }
          });
      }
    });
    this.getUsers();
    this.loading_count++;
    const banksRef = firebase.database().ref("constants/banks");
    banksRef
      .once("value")
      .then((snapshot) => {
        return snapshot.val();
      })
      .then((value) => {
        for (const bank in value) {
          this.bankList.push({
            label: value[bank],
            value: value[bank],
          });
        }
      })
      .finally(() => {
        this.loading_count--;
      });
  },
};
</script>
