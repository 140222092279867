import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyCz48H7GDSWlnN2Of7s2J3VO2g4FdEAfIU",
  authDomain: "joy-food-lounge-4358b.firebaseapp.com",
  databaseURL: "https://joy-food-lounge-4358b-default-rtdb.firebaseio.com",
  projectId: "joy-food-lounge-4358b",
  storageBucket: "joy-food-lounge-4358b.appspot.com",
  messagingSenderId: "660120977828",
  appId: "1:660120977828:web:d9d5b583ed56875b5bc131",
  measurementId: "G-3Z4CMSZ95F"
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "Joy Food Lounge"; //公司名稱
      },
      get companyDomain() {
        return "https://joyfood-lounge.xyz/"; //公司HR系統網址
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}